<template>
  <div>
    <div v-if="getVisibleUndeliveredAtom">
      <img :src="getUndeliveredAtomCallImage" class="atom_call" @click="goToUndeliveredAtomUrl" />
      <div @click="closePopup('OkClick')" class="atom_call_ok">
        <span class="ok_btn_text"> TAMAM </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UndeliveredAtomCallPopup',
  computed: {
    ...mapGetters('app', ['getPopups']),
    getVisibleUndeliveredAtom() {
      return this.getPopups.popupId == 1 && this.$route.fullPath == '/secure/feed';
    },
    getUndeliveredAtomCallImage() {
      return this.getPopups?.popupDetail?.image ?? '';
    },
  },
  methods: {
    setEventData(isClick) {
      return {
        event: 'cEvent',
        category: 'Pop Up',
        action: isClick ? 'Click' : 'Close',
        label: 'AtomCall',
        value: 0,
        isNonInteraction: false,
      };
    },
    closePopup(action) {
      const eventData = this.setEventData(false);
      this.$emit('closePopup', eventData, { type: action });
    },
    goToUndeliveredAtomUrl() {
      if (this.getPopups?.popupDetail?.url) {
        this.$router.push(`${this.getPopups.popupDetail.url}`);
        this.closePopup();
      }

      const eventData = this.setEventData(true);
      this.$emit('closePopup', eventData, { type: 'LinkClick' });
    },
  },
};
</script>
<style lang="scss" scoped>
.atom_call {
  position: absolute;
  width: 490px;
  height: 700px;
  z-index: 26;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0);
}
.atom_call_ok {
  width: 490px;
  height: 69px;
  padding: 22px 204.5px 21px;
  object-fit: contain;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  z-index: 26;
  top: 820px;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
}
.ok_btn_text {
  width: 81px;
  height: 26px;
  object-fit: contain;
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e5472d;
}
</style>
